import React, { forwardRef, type CSSProperties, type MouseEvent } from 'react'
import styled from 'styled-components/macro'

import {
  fontSizes,
  margins,
  colors,
  fontWeights,
  media,
  borderRadiuses,
  BUTTON_HEIGHT,
  borders,
} from 'css/css'

import smallLoader from 'img/shared/small-loader.svg'
import { useNotifContext } from 'js/providers/notif-context'

const backgroundColors = {
  blue: {
    default: colors.primaryBlueMain,
    hover: colors.primaryBluePale,
    textColor: colors.whiteMain,
  },
  secondaryBlue: {
    default: colors.secondaryBlueMain,
    hover: colors.secondaryBluePale,
    textColor: colors.whiteMain,
  },
  green: {
    default: colors.greenMain,
    hover: colors.greenDark,
    textColor: colors.whiteMain,
  },
  grey: {
    default: colors.greyMain,
    hover: colors.greyDark,
    textColor: colors.whiteMain,
  },
  red: {
    default: colors.redMain,
    hover: colors.redDark,
    textColor: colors.whiteMain,
  },
}

interface Props {
  large?: boolean
  small?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  isInvalid?: boolean
  invalidCallback?: (e: MouseEvent<HTMLButtonElement>) => void
  invalidMessage?: string
  style?: CSSProperties
  className?: string
  color?: 'green' | 'blue' | 'grey' | 'red' | 'secondaryBlue'
  plain?: boolean
  isSelected?: boolean
  isDropDown?: boolean
  square?: boolean
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
  isLoadingText?: string
  dataTestId?: string
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      large,
      small,
      onClick,
      isLoading,
      isInvalid,
      invalidCallback,
      invalidMessage,
      children,
      color,
      plain,
      isSelected,
      isDropDown,
      square,
      isLoadingText,
      dataTestId,
      ...rest
    },
    forwardedRef,
  ) => {
    const { showNotif } = useNotifContext()

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (isInvalid) {
        if (invalidCallback != null) invalidCallback(e)
        if (invalidMessage) {
          showNotif({
            message: invalidMessage,
            level: 'error',
          })
        }
      } else if (!isLoading && onClick != null) {
        onClick(e)
      }
    }

    return (
      <StyledButton
        ref={forwardedRef}
        onClick={handleClick}
        $isInvalid={isLoading || isInvalid}
        $large={large}
        $small={small}
        $color={color}
        $plain={plain}
        $isSelected={isSelected}
        $isDropDown={isDropDown}
        $square={square}
        data-testid={dataTestId}
        {...rest}
      >
        {isLoading ? (
          <>
            <img src={smallLoader} alt="" />
            {isLoadingText || ''}
          </>
        ) : (
          children
        )}
      </StyledButton>
    )
  },
)

Button.displayName = 'Button'

interface StyledButton {
  $isInvalid?: boolean
  $large?: boolean
  $small?: boolean
  $color?: 'green' | 'blue' | 'grey' | 'red' | 'secondaryBlue'
  $plain?: boolean
  $isSelected?: boolean
  $isDropDown?: boolean
  $square?: boolean
}
const StyledButton = styled.button<StyledButton>`
  width: ${(p) => (p.$large ? '100%' : p.$plain || p.$small ? 'fit-content' : '180px')};
  background-color: ${(p) =>
    p.$plain
      ? 'transparent'
      : p.$isInvalid
      ? backgroundColors[p.$color || 'blue'].hover
      : backgroundColors[p.$color || 'blue'].default};

  color: ${(p) =>
    p.$isInvalid
      ? colors.whiteOpaque
      : p.$plain && p.$isSelected
      ? colors.primaryBlueMain
      : p.$plain
      ? colors.whiteOpaque
      : p.$color
      ? backgroundColors[p.$color].textColor
      : colors.whiteMain};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${(p) => (p.$square ? 0 : borderRadiuses.standard)};
  border: none;

  height: ${BUTTON_HEIGHT};
  min-height: ${BUTTON_HEIGHT};
  font-weight: ${fontWeights.light};
  font-variation-settings: 'wght' ${fontWeights.light};
  font-size: ${(p) => (p.$plain ? fontSizes.size3 : fontSizes.size4)};
  padding: ${margins.size4};
  cursor: ${(p) => (p.$isInvalid ? 'default' : 'pointer')};
  transition: 0.5s;
  will-change: transform;
  white-space: nowrap;
  text-overflow: ellipsis;

  > * {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > p {
    color: ${(p) =>
      p.$isInvalid
        ? colors.whiteOpaque
        : p.$plain && p.$isSelected
        ? colors.primaryBlueMain
        : p.$plain
        ? colors.whiteOpaque
        : p.$color
        ? backgroundColors[p.$color].textColor
        : colors.whiteMain};
  }

  &:focus {
    outline: none;
    ${(p) =>
      !p.$isInvalid &&
      p.$isDropDown &&
      `
        color: ${colors.whiteMain};
    `}
  }

  &:hover {
    ${(p) =>
      !p.$isInvalid &&
      p.$isDropDown &&
      `
        color: ${colors.whiteMain};
        background-color: ${backgroundColors[p.$color || 'blue'].hover};    `}
    ${(p) => p.$plain && `border: ${borders.thickMain}`}
    background-color: ${(p) =>
      p.$plain ? 'transparent' : backgroundColors[p.$color || 'blue'].hover};
    transition: 0.1s;
  }

  ${media.mobile} {
    padding: ${(p) => (p.$plain ? `0 ${margins.size3}` : `0 ${margins.size4}`)};
  }
`
