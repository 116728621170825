import React, { createContext, useContext, useState, type ReactElement } from 'react'
import _uniqueId from 'lodash/uniqueId'

import {
  CheckMark,
  FailedMark,
  FlexColumn,
  FlexRow,
  LightText1,
  Text4,
  colors,
  margins,
} from 'css/css'

import { NotificationPopup } from 'js/shared-components/NotificationPopup'

import blackCheck from 'img/shared/blackCheck.svg'
import errorSvg from 'img/shared/x.svg'

export interface Notification {
  title?: string
  message?: string | ReactElement
  level?: 'error' | 'warning' | 'info' | 'success'
  autoDismiss?: boolean
  dismissible?: boolean
  children?: React.ReactNode
  onAdd?: () => void
  onRemove?: () => void | Promise<void>
  uid?: number | string
}

interface ContextProps {
  showNotif: (notif: Notification) => void
}

const NotifContext = createContext<ContextProps>({
  showNotif: () => undefined,
})

export const useNotifContext = () => useContext(NotifContext)

export const NotifProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifs, setNotifs] = useState<Notification[]>([])

  const showNotif = (notif: Notification) => {
    const { title, message, level, autoDismiss, onRemove } = notif
    const notifLevel = level || 'success'
    const shouldDismiss = autoDismiss !== false
    const newNotif = {
      ...notif,
      onRemove,
      title,
      message,
      level: notifLevel,
      uid: _uniqueId(),
    }
    setNotifs([...notifs, newNotif])
    if (shouldDismiss) {
      setTimeout(() => setNotifs((prev) => prev.filter((n) => n.uid !== newNotif.uid)), 5000)
    }
  }

  const contextValue = { showNotif }

  return (
    <NotifContext.Provider value={contextValue}>
      {notifs.map((notif, idx) => (
        <NotificationPopup
          key={notif.uid}
          isOpen={true}
          closePopup={() => {
            setNotifs((prev) => prev.filter((n) => n.uid !== notif.uid))
            if (notif.onRemove) notif.onRemove()
          }}
          topOffset={idx}
          isBottomAligned
        >
          <FlexRow
            style={{ gap: margins.size2, alignItems: 'center' }}
            data-testid={`${notif.level}-notification`}
          >
            {!!notif.level &&
              (notif.level === 'success' ? (
                <CheckMark src={blackCheck} style={{ color: colors.greenMain }} />
              ) : (
                <FailedMark src={errorSvg} alt="Failed" />
              ))}
            <FlexColumn>
              {!!notif.title && <Text4 style={{ color: colors.whiteMain }}>{notif.title}</Text4>}
              <LightText1 style={{ color: colors.whiteMain }}>{notif.message}</LightText1>
            </FlexColumn>
          </FlexRow>
        </NotificationPopup>
      ))}
      {children}
    </NotifContext.Provider>
  )
}
