import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components/macro'

import { colors } from 'css/css'

import { cancelIcon } from 'img/shared'

interface Props {
  color?: string
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  justify?: 'flex-start' | 'flex-end' | 'center'
  dataTestId?: string
}

export const CloseButton: React.FC<Props> = ({
  color = colors.whiteMain,
  className,
  onClick,
  style,
  justify,
  dataTestId,
}) => {
  return (
    <Container
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={className}
      style={{
        ...style,
        justifyContent: justify,
      }}
      data-testid={dataTestId}
    >
      <Close src={cancelIcon} $color={color} />
    </Container>
  )
}

const Container = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Close = styled(SVG)<{ $color: string }>`
  > path {
    stroke: ${(p) => p.$color};
  }
`
