import { fontSizes, StyledInput } from 'css/css'
import styled from 'styled-components'

export const PercentageInput = styled(StyledInput)`
  border: none;
  overflow: hidden;
  height: unset;
  font-size: ${fontSizes.size2};
  text-indent: 0;
`
