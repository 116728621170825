import styled, { keyframes } from 'styled-components/macro'
import { Link as routerLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'

import { MOBILE_WIDTH, TABLET_WIDTH } from 'js/constants/shared'

export const media = {
  mobile: `@media screen and (max-width: ${MOBILE_WIDTH}px)`,
  tablet: `@media screen and (max-width: ${TABLET_WIDTH}px)`,
  desktop: `@media screen and (min-width: ${TABLET_WIDTH + 1}px)`,
}

export const globalTransitionSettings = 'all 0.2s ease;   -moz-transition-property: none;'

export const fonts = {
  satoshi: 'Satoshi variable, -apple-system, BlinkMacSystemFont, sans-serif;',
}

export const fontWeights = {
  /** 200 */
  lightest: 200,
  /** 300 */
  light: 300,
  /** 500 */
  bold: 500,
  /** 700 */
  extraBold: 700,
  black: 800,
}

export const fontSizes = {
  /** 9px */
  size0: '9px',
  /** 10px */
  size1: '10px',
  /** 12px */
  size2: '12px',
  /** 14px */
  size3: '14px',
  /** 16px */
  size4: '16px',
  /** 18px */
  size5: '18px',
  /** 22px */
  size6: '22px',
  /** 24px */
  size7: '24px',
  /** 32px */
  size8: '32px',
  /** 40px */
  size9: '40px',
  /** 48px */
  size10: '48px',
  /** 64px */
  size20: '64px',
}

export const colors = {
  black: '#10111b',
  blackDarkest: '#0d0e16',

  greyDarkest: '#17181B',
  greyDark: '#1f2128',
  blueGrey: '#262D44',
  greyMain: '#2F343E',
  greyMedium: '#39393B',
  greyLight: '#444554',
  greyLightest: '#49494B',

  whiteMain: '#FFFFFF',
  whiteOpaque: '#B0B7C3',

  greenMain: '#4AB683',
  greenPale: '#03f78528',
  greenDark: '#31502F',

  yellowBright: '#FACC15',
  yellowPale: '#F59E0B33',

  redMain: '#E85866',
  redPale: '#fa506147',
  redDark: '#513134',

  primaryBlueMain: '#206EE3',
  primaryBluePale: '#16407E',

  secondaryBlueMain: '#1A34A6',
  secondaryBluePale: '#18255B',

  purple: '#5B27F0',
  purplePale: '#8B5CF633',
} as const

export const margins = {
  size1: '4px',
  size2: '8px',
  size3: '12px',
  size4: '16px',
  size5: '24px',
  size6: '32px',
  size7: '48px',
  size8: '64px',
  size9: '128px',
} as const

export const borders = {
  mainBorder: `1px solid ${colors.greyMain}`,
  thickMain: `2px solid ${colors.greyMain}`,
} as const

export const borderRadiuses = {
  standard: '7px',
}

export const BUTTON_HEIGHT = '40px'

export const shadows = {
  darkTopShadow: ` 0 -5px 10px ${colors.black}`,
  darkBottomShadow: ` 0 5px 10px ${colors.black}`,
  lightBottomShadow: `0 5px 10px ${colors.whiteMain}`,
  darkBoxShadow: `0px 0px 10px ${colors.black}`,
  darkestBoxShadow: `0px 0px 10px ${colors.blackDarkest}`,
  largeDark: '2px 4px 28px 1px rgba(0, 0, 0, 0.5)',
  lightShadow: '0px 3px 10px 2px #191b2a',
} as const

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text0 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size0};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text1 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size1};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text2 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size2};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text3 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size3};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text4 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size4};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text5 = styled.p`
  white-space: nowrap;
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size5};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text6 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size6};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
  line-height: 1;
`

export const Text7 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size7};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text8 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size8};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Text9 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size9};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.black};
  margin-bottom: ${margins.size2};
`

export const Text10 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size10};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText0 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size1};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText1 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size2};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText2 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size3};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText3 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size4};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText4 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size5};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText5 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size6};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText6 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size7};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const LightText7 = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size8};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const TitleText = styled.p`
  margin: 0;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size9};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.light};
`

export const Clickable = styled.button<{ $shadowOnHover?: boolean }>`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0px;
  transition: transform 0.5s;
  will-change: transform;
  color: ${colors.whiteOpaque};

  > p {
    font-family: ${fonts.satoshi};
  }

  :focus {
    outline: none;
  }

  &:hover {
    ${(p) => p.$shadowOnHover && 'background: rgb(0 0 0 / 20%)'};
    transition: transform 0.1s;
    color: ${colors.whiteMain};
  }

  &:active {
    outline: none;
  }
`

export const Link = styled(routerLink)`
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
  }
`

// type UnderlineAnimationProps = { isHovering?: boolean }

// const underlineHover = css`
//   transform-origin: bottom left;
//   transform: scaleX(1);
// `

// const underlineAnimation = css<UnderlineAnimationProps>`
//   position: relative;

//   &:before {
//     content: '';
//     width: 100%;
//     position: absolute;
//     height: 1px;
//     bottom: 0;
//     left: 0;

//     background-color: ${colors.primaryBlueMain};
//     transform-origin: bottom right;

//     transform: scaleX(0);
//     transition: ${globalTransitionSettings};
//     ${(p) => p.isHovering && 'transform-origin: bottom left; transform: scaleX(1);'}
//   }

//   &:hover:before {
//     ${underlineHover}
//   }
// `

// export const AnimatedLink = styled.span<UnderlineAnimationProps>`
//   color: ${colors.whiteMain};
//   cursor: pointer;
//   ${underlineAnimation}
// `

export const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  overflow-y: hidden;
  border: ${borders.mainBorder};
  border-right: none;
  border-top: none;
  &:first-child {
    border-left: none;
  }
`

export const Triangle = styled(SVG)<{ $isUp: boolean }>`
  color: ${(p) => (p.$isUp ? colors.greenMain : colors.redMain)};
  transform: rotate(${(p) => (p.$isUp ? '180' : '0')}deg);
  margin-right: ${margins.size1};
`

export const COMPONENTS_GAP = 0

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const SkeletonAnimation = styled.div`
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: ${colors.greyDark};
  border-radius: inherit;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, #1f2128 0, #2f343e 20%, #3f444d 80%, #334155);
    animation: ${shimmer} 2s infinite;
    content: '';
    opacity: 0.5;
  }
`

export const SkeletonCircle = styled(SkeletonAnimation)`
  border-radius: 50%;
  height: 32px;
  width: 32px;
`

export const SkeletonRectangle = styled(SkeletonAnimation)`
  height: 100%;
  width: 100%;
`

export const slideUp = keyframes`
0% {
      transform:  translateY(100%);
      opacity: 0;
  }
  100% {
      transform:  translateY(0px);
      opacity: 1;
  }
`

export const WarningContainer = styled(FlexRow)`
  background-color: ${colors.redDark};
  padding: ${margins.size4};
  border-radius: ${borderRadiuses.standard};
  position: relative;
  max-width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 5px;
    background-color: ${colors.redMain};
    border-radius: ${borderRadiuses.standard} 0 0 ${borderRadiuses.standard};
  }
`

export const Table = styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
`

export const TableRow = styled.tr<{ $isClickable?: boolean }>`
  min-height: 40px;
  max-height: 40px;
  height: 40px;

  &:not(:last-child) {
    border-bottom: ${borders.mainBorder};
  }

  ${media.mobile} {
    &:last-child {
      border-bottom: none;
    }
  }
  ${({ $isClickable }) =>
    $isClickable &&
    `&:hover {
      cursor: pointer;
      background: ${colors.greyDark} !important;
      p {
        text-shadow: 0 0 1px currentColor;
      }
    }
  `}
`

export const ValueFlexRow = styled(FlexRow)`
  gap: ${margins.size1};
  align-items: center;
  justify-content: center;
`

export const ValueFlexCol = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: center;
`

export const HeaderRow = styled(TableRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #10111b3b;
  backdrop-filter: blur(10px);
  &:hover {
    cursor: default;
  }
`

export const RowValue = styled.td`
  max-height: 40px;

  padding-left: ${margins.size2};
  padding-right: ${margins.size2};

  &:last-child {
    text-align: right;
  }
`

export const StyledInput = styled.input`
  background-color: transparent;
  border-radius: ${borderRadiuses.standard};
  border: ${borders.mainBorder};
  height: 44px;
  outline: none;
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size5};
  text-indent: ${margins.size3};
  font-family: ${fonts.satoshi};

  &::placeholder {
    color: ${colors.whiteOpaque};
    opacity: 0.5;
    text-indent: ${margins.size3};
  }

  :-internal-autofill-selected {
    -webkit-text-fill-color: ${colors.whiteMain} !important;
    box-shadow: 0 0 0 30px ${colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${colors.black} inset !important;
    transition: background-color 5000s;
  }

  :-internal-autofill-previewed {
    -webkit-text-fill-color: ${colors.whiteMain} !important;
    box-shadow: 0 0 0 30px ${colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${colors.black} inset !important;
    transition: background-color 5000s;
  }
  &:autofill {
    -webkit-text-fill-color: ${colors.whiteMain} !important;
    box-shadow: 0 0 0 30px ${colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${colors.black} inset !important;
    transition: background-color 5000s;
  }

  ::-webkit-autofill {
    -webkit-text-fill-color: ${colors.whiteMain} !important;
    box-shadow: 0 0 0 30px ${colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${colors.black} inset !important;
    transition: background-color 5000s;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

export const CheckMark = styled(SVG)`
  border-radius: 50%;
  border: 1px solid ${colors.greenMain};
  max-height: 15px;
  max-width: 15px;
`

export const FailedMark = styled.img`
  border-radius: 50%;
  border: 1px solid #ff0000;
  max-height: 15px;
  max-width: 15px;
`
