import React, { type CSSProperties, type MouseEvent } from 'react'
import styled from 'styled-components/macro'

import {
  borders,
  fontSizes,
  colors,
  fontWeights,
  FlexColumn,
  fonts,
  media,
  BUTTON_HEIGHT,
} from 'css/css'

interface Props {
  isSelected: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  style?: CSSProperties
  className?: string
  children: React.ReactNode
  setRef?: (element: HTMLElement | null, key: number) => void
  id?: number
  dataTestId?: string
}

export const TabButton: React.FC<Props> = ({
  isSelected,
  onClick,
  children,
  style,
  className,
  setRef,
  id,
  dataTestId,
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick != null) {
      onClick(e)
    }
  }

  return (
    <StyledButton
      ref={(element) => setRef && id !== undefined && setRef(element, id)}
      className={className}
      onClick={handleClick}
      $isSelected={isSelected}
      style={style}
      data-testid={dataTestId}
    >
      {children}
    </StyledButton>
  )
}

export const TabBar = styled(FlexColumn)`
  flex-direction: row;
  border-bottom: ${borders.mainBorder};
  height: ${BUTTON_HEIGHT};
  min-height: ${BUTTON_HEIGHT};
  align-items: flex-start;
  justify-content: center;
  position: relative;
`

interface StyledButton {
  $isSelected?: boolean
}
const StyledButton = styled.button<StyledButton>`
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${(p) => (p.$isSelected ? colors.whiteMain : colors.whiteOpaque)};
  height: ${BUTTON_HEIGHT};
  min-height: ${BUTTON_HEIGHT};
  font-family: ${fonts.satoshi};
  font-size: ${fontSizes.size3};
  font-feature-settings: 'tnum';
  font-variation-settings: 'wght' ${fontWeights.bold};
  font-style: normal;
  padding: 0;
  cursor: pointer;
  transition: 0.5s;
  will-change: transform;
  white-space: nowrap;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%);
  }
  &:hover {
    color: ${colors.whiteMain};
    transition: 0.1s;
  }

  ${media.mobile} {
    padding: 0;
    border-top: none;
  }
`

export const SelectorIndicator = styled.div<{
  $numButtons: number
  $selectedElemIdx: number
  $left?: number
  $width?: number
}>`
  position: absolute;
  width: ${(p) => (p.$width ? `${p.$width}px` : `calc(100% / ${p.$numButtons})`)};
  left: ${(p) =>
    p.$left ? `${p.$left}px` : `calc(100% / ${p.$numButtons} * ${p.$selectedElemIdx})`};
  transition: left 350ms ease;
  bottom: 0;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background-color: ${colors.primaryBlueMain};
    margin: auto;
    border-radius: 80px 80px 0px 0px;
    position: relative;
  }
`
