import {
  FlexColumn,
  borderRadiuses,
  borders,
  colors,
  globalTransitionSettings,
  margins,
  media,
  shadows,
} from 'css/css'

import styled, { keyframes } from 'styled-components/macro'
import { CloseButton } from 'js/shared-components/CloseButton'
import { useState } from 'react'

export const NotificationPopup = ({
  children,
  isOpen,
  closePopup,
  isBottomAligned,
  topOffset = 0,
}: {
  children: React.ReactNode
  isOpen: boolean
  closePopup: () => void
  isBottomAligned: boolean
  topOffset?: number
}) => {
  const [isHovered, setIsHovered] = useState(false)
  if (!isOpen) return null

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $topOffset={topOffset}
      data-testid="order_notification_container"
      $isBottomAligned={isBottomAligned}
    >
      {isHovered && (
        <StyledCloseButton
          color={colors.whiteOpaque}
          onClick={closePopup}
          dataTestId="order_notification_close_button"
        />
      )}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}

const Container = styled(FlexColumn)<{ $topOffset: number; $isBottomAligned: boolean }>`
  z-index: 10;
  position: absolute;
  top: ${(p) => (!p.$isBottomAligned ? `calc(${margins.size3} + ${p.$topOffset * 4}px)` : 'unset')};
  bottom: ${(p) =>
    p.$isBottomAligned ? `calc(${margins.size3} + ${p.$topOffset * 8}px)` : 'unset'};
  right: ${margins.size3};
  ${(p) => !p.$topOffset && `box-shadow: ${shadows.lightShadow}`};

  ${media.mobile} {
    top: calc(${margins.size3} + ${({ $topOffset }) => $topOffset * 4}px);
    bottom: unset;
    max-width: 250px;
    width: 250px;
    ${(p) => p.$isBottomAligned && 'z-index: 11'};
    ${(p) => p.$isBottomAligned && 'left: 50%; transform: translateX(-50%);'};
  }
`

const appear = keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
  `

const ContentContainer = styled(FlexColumn)`
  border: ${borders.mainBorder};
  border-radius: ${borderRadiuses.standard};
  width: 240px;
  padding: ${margins.size3};
  background-color: ${colors.blackDarkest};
  color: ${colors.whiteMain};
  transition: ${globalTransitionSettings};
  display: flex;
  align-items: center;
  animation: ${appear} 150ms ease-in-out forwards;
  transform-origin: top center;
  gap: ${margins.size2};
  ${media.mobile} {
    padding: ${margins.size3};
    width: 100%;
  }
`

const StyledCloseButton = styled(CloseButton)`
  align-self: flex-end;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${colors.blackDarkest};
  border-radius: 50%;
  border: ${borders.mainBorder};
  width: unset !important;
  height: unset !important;
  z-index: 5;
`
